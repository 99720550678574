import { combineReducers } from 'redux';
import api_key from './api_key';
import auto_stash_overview from './auto_stash_overview';
import user from './user';
import profile from './profile';
import account from './account';
import account_by_type from './account_by_type';
import account_verification from './account_verification';
import advisory_agreements from './advisory_agreements';
import agreements from './agreements';
import first_cards from './first_cards';
import cards from './cards';
import cards_by_ticker from './cards_by_ticker';
import card_groups from './cardGroups';
import cards_by_group from './cardsByGroup';
import conversion from './conversion';
import funding_source from './funding_source';
import funding_source_by_type from './funding_source_by_type';
import gifting_urls from './gifting_urls';
import investor_application from './investor_application';
import investor_application_questions from './investor_application_questions';
import eligible_account_types from './eligible_account_types';
import eligible_platform_tiers from './eligible_platform_tiers';
import bank_account from './bank_account';
import quotes from './quotes';
import prices from './prices';
import invest_accounts from './invest_accounts';
import tax_document from './tax_document';
import statement from './statement';
import trade_confirmations from './trade_confirmations';
import account_history from './account_history';
import phoneVerification from './phoneVerification';
import property_insurance_quote from './property_insurance_quote';
import referral_data from './referral_data';
import referralsManagementView from './referralsManagementView';
import referralsHomeView from './referralsHomeView';
import bookmarks from './bookmarks';
import bank from './harmonyBank';
import notifications from './notifications';
import insights_report from './insights_report';
import transfers from './transfers';
import user_guidance from './user_guidance';
import retention_offer from './retention_offer';
import recurringTransfer from './recurringTransfers';
import launch_info from './launch_info';
import subscriptionActions from './subscriptionActions';
import externalAccount from './externalAccount';
import income from './income';

export default combineReducers({
	api_key,
	auto_stash_overview,
	account_verification,
	advisory_agreements,
	property_insurance_quote,
	account,
	account_by_type,
	account_history,
	agreements,
	bank,
	bank_account, //FIME: move under account
	bookmarks,
	cards,
	cards_by_ticker,
	card_groups,
	cards_by_group,
	conversion,
	first_cards,
	funding_source,
	funding_source_by_type,
	gifting_urls,
	investor_application,
	investor_application_questions,
	eligible_account_types,
	eligible_platform_tiers,
	launch_info,
	profile,
	phoneVerification,
	prices,
	invest_accounts,
	quotes,
	referralsManagementView,
	referralsHomeView,
	statement,
	tax_document,
	trade_confirmations,
	transfers,
	user,
	referral_data,
	notifications,
	insights_report,
	user_guidance,
	retention_offer,
	recurringTransfer,
	subscriptionActions,
	externalAccount,
	income,
});
